import React from "react"

class Benificial extends React.Component {

  // Tab
  openTabSection = (evt, tabName) => {
    let i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName("tabs_items");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].classList.remove("fadeIn");
      tabcontent[i].style.display = "none";
    }

    tablinks = document.getElementsByTagName("li");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace("current", "");
    }

    document.getElementById(tabName).style.display = "block";
    document.getElementById(tabName).className += " fadeIn animated";
    evt.currentTarget.className += "current";
  }

  render() {
    return (

      <section className="usr-admin-tab benifor central pt-100 mb-0">
        <div className="gray-bg">
          <div className="container"> 
            <h2 className="heading-h2 text-center"><span className="bluecolor">Features Of </span> Our Centralized Cryptocurrency Exchange Development - Transforming Trades, Empowering Investors! 
            </h2>
            <p className="text-center">
            We include basic and advanced-level features for your cryptocurrency exchange development. Our developer team turns complexity into simplicity at ease.
            </p>
            <div className="row">
            <ul className="nav nav-tabs tabNav nav-pills" id="myTab" role='presentation'>
                <li className="current" role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab1')}>
                  Basic Features
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab2')} >
                  Advanced Features
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab3')} >
                  Add-Ons
                </li>
              </ul>
              <div className="tab-content px-0">
                <div id="tab1" className="tab-pane tabs_items active">
                  <div className="d-lg-flex flex-wrap add-one">
                    <div className="square" >
                      <p className="head3">Signup & Login Interface</p>
                      <p className="pharagraph">
                      We guarantee a user-friendly experience by giving you a simple way to sign up and login.
                      </p>
                    </div>
                    <div className="square" >
                      <p className="head3">P2P Trading</p>
                      <p className="pharagraph">
                      Users can directly interact and transact with each other directly through our P2P trading, eliminating the need for intermediaries.
                      </p>
                    </div>
                    <div className="square" >
                      <p className="head3">Spot Trading</p>
                      <p className="pharagraph">
                      We provide a user-friendly interface for spot trading, thus allowing your users to execute trades swiftly with robust security measures.
                      </p>
                    </div>
                    <div className="square" >
                      <p className="head3">Multi-lingual Support</p>
                      <p className="pharagraph">
                      We engage worldwide users with our centralized exchange development platform by offering audiences multi-lingual support.
                      </p>
                    </div>
                    <div className="square" >
                      <p className="head3">Multi-Currency Support</p>
                      <p className="pharagraph">
                      We provide a range of digital assets to reach a larger user base. Offering a wide variety of cryptocurrencies allows users to trade with freedom.
                      </p>
                    </div>
                    <div className="square" >
                      <p className="head3">Cold Wallet Integration</p>
                      <p className="pharagraph">
                      We prioritize security and safeguard users' trading with robust cold wallet integration, thus ensuring the offline storage of digital assets.
                      </p>
                    </div>
                  </div>
                </div>
                <div id="tab2" className="tab-pane tabs_items">
                  <div className="d-lg-flex flex-wrap add-one">
                    <div className="square" >
                      <p className="head3">Exclusive Admin Panel</p>
                      <p className="pharagraph">
                      We provide a holistic admin panel providing control over platform management, user accounts, security settings, liquidity management, etc.
                      </p>
                    </div>
                    <div className="square" >
                      <p className="head3">OTC Trading</p>
                      <p className="pharagraph">
                      Our centralized crypto exchange development has an Over-The-Counter (OTC) trading feature, exclusively for users who seek to trade large volumes of digital assets. 
                      </p>
                    </div>
                    <div className="square" >
                      <p className="head3">KYC/AML Support</p>
                      <p className="pharagraph">
                      Our centralized cryptocurrency exchange development integrates Know Your Customer (KYC) and Anti-Money Laundering (AML) protocols into your exchange platform.  
                      </p>
                    </div>
                    <div className="square" >
                      <p className="head3">Transactions & Trade History</p>
                      <p className="pharagraph">
                      We offer users to track their transaction history and trade activity by incorporating features for it. Thus we offer transparency and accountability to your exchange.
                      </p>
                    </div>
                    <div className="square" >
                      <p className="head3">Time-Limited Transactions</p>  
                      <p className="pharagraph">
                      We indulged the time-limit transactions for users to set an expiry date for the tradings. With this, we bring flexibility and control over your user's trading.
                      </p>
                    </div>
                    <div className="square" >
                      <p className="head3">Data Encryption</p>
                      <p className="pharagraph">
                      We implement end-to-end data encryption for your users that will safeguard all your sensitive information, such as user credentials, transaction details, personal data, etc.
                      </p>
                    </div>
                  </div>
                </div>
                <div id="tab3" className="tab-pane tabs_items">
                  <div className="d-lg-flex flex-wrap add-one">
                    <div className="square" >
                      <p className="head3">NFT Provisions</p>
                      <p className="pharagraph">
                      As a supreme centralized exchange development platform, we offer Non-Fungible Token (NFT) provisions into your exchange platform. With this integration, we'll also cover up the NFT enthusiasts to your exchange platform.
                      </p>
                    </div>
                    <div className="square" >
                      <p className="head3">Crowdfunding Support</p>
                      <p className="pharagraph">
                      We will indulge the crowdfunding capabilities within our cryptocurrency exchange development platform. Our exchange platform supports IEO and STO listings which empowers the users to raise funds.
                      </p>
                    </div>
                    <div className="square" >
                      <p className="head3">Launchpad</p>
                      <p className="pharagraph">
                      We provide a launchpad feature as an add-on, where your crypto exchange serves as a platform for hosting token sales and IEOs, to provide opportunities for project fundraising.
                      </p>
                    </div>
                    <div className="square" >
                      <p className="head3">Sub-Admin Integration</p>
                      <p className="pharagraph">
                      We exclusively include sub-admin integration functionality into your centralized exchange. We enhance administration operations by managing the specific aspects of the platform.
                      </p>
                    </div>
                    <div className="square" >
                      <p className="head3">Crypto Trading Bot</p>
                      <p className="pharagraph">
                      Our centralized cryptocurrency exchange development gives you the efficiency of the platform by indulging the trading bot feature. This feature enables the users to automate and optimize their trading strategies.
                      </p>
                    </div>
                    <div className="square" >
                      <p className="head3">Anti-Phishing Integration</p>
                      <p className="pharagraph">
                      As a centralized exchange development service company, we prioritize your privacy and security. We incorporated a robust anti-phishing feature that will protect you from any fraudulent activities and phishing attacks.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default Benificial
