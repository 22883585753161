import React, { useState } from "react";
import { Accordion, Container } from 'react-bootstrap'



const FaqSection = () => {


  const [activeId, setActiveId] = useState('');

  function toggleActive(id) {
    if (activeId === id) {
      setActiveId(null);
    } else {
      setActiveId(id);
    }
  }

  return (
    <section className="faq pt-100">
      <Container>
        <h6 className="main-heading text-center">Frequently Asked Questions</h6>
        <Accordion defaultActiveKey={activeId}>
            <div className={activeId === '0' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('0')} className="panel-toggle" eventKey="0">
                How to start a centralized exchange?
                </Accordion.Toggle>
              </div>
              <Accordion.Collapse eventKey="0">
                <div className="panel-body card-body">To start a centralized exchange there are a few ways, the best is to go for a readymade clone script or a Whitelabel centralized exchange solution. Apart from these 2 options, one can start from scratch also. Further, Coinsclone is the best centralized exchange development company that is well-versed in providing all kinds of centralized exchange development services. 
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '1' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('1')} className="panel-toggle"  eventKey="1">
                What is the cost of developing a centralized exchange?
                </Accordion.Toggle>
              </div>
              <Accordion.Collapse eventKey="1">
                <div className="panel-body card-body">The cost to develop a centralized crypto exchange costs around <b>$10,000 to $20,000</b>. This cost may vary for each project. Based on the complexity of the project, customizations, and feature integration the cost may change.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '2' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('2')} className="panel-toggle"  eventKey="2">
                Are Centralized Exchanges safe?
                </Accordion.Toggle>
              </div>
              <Accordion.Collapse eventKey="2">
                <div className="panel-body card-body">Centralized exchanges are safe based on the exchange platform provider you choose. Only top leading centralized exchange providers like Coinsclone will make it safe for your users by providing insurance, robust security measures, regulation and compliance, etc. 
                </div>
              </Accordion.Collapse>
            </div> 
            <div className={activeId === '3' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('3')} className="panel-toggle"  eventKey="3">
                What are the features of centralized exchanges?
                </Accordion.Toggle>
              </div>
              <Accordion.Collapse eventKey="3">
                <div className="panel-body card-body">Some of the supreme features of centralized exchanges are an admin panel, KYC/AML factors, CMS management, managing liquidity API, trading pair management, wallet system, order book, analytical trade report, transaction and trade history, launchpad management, ico support, etc...
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '4' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('4')} className="panel-toggle"  eventKey="4">
                What is a centralized exchange script?
                </Accordion.Toggle>
              </div>
              <Accordion.Collapse eventKey="4">
                <div className="panel-body card-body">A centralized exchange script is a pre-fabricated script that supports you with all the necessary features and functionalities of a centralized exchange. Starting from the framework, UI/UX, and development, till deployment and post-launch support and maintenance, everything will be integrated into the centralized exchange script.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '5' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('5')} className="panel-toggle"  eventKey="5">
                Which is the best centralized cryptocurrency exchange development company?
                </Accordion.Toggle>
              </div>
              <Accordion.Collapse eventKey="5">
                <div className="panel-body card-body">Among the several companies, Coinsclone is a leading centralized cryptocurrency exchange development company. In addition to offering centralized exchange development services, we also offer extensive centralized services as well.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '6' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('6')} className="panel-toggle"  eventKey="6">
                What is the difference between centralized and decentralized crypto exchange development?
                </Accordion.Toggle>
              </div>
              <Accordion.Collapse eventKey="6">
                <div className="panel-body card-body">In a centralized cryptocurrency exchange development platform the trading takes place with a central authority. The exchange operator has complete control and security of the user's transactions, whereas a decentralized exchange development platform operates without the central authority. Centralized exchange is a more common and user-friendly option for the user whereas decentralized exchange prioritizes decentralization and security. 
                </div>
              </Accordion.Collapse>
            </div>
        </Accordion> 
      </Container>
    </section>
  )
}

export default FaqSection